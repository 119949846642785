import React from 'react'
import { FiPhoneCall, FiMapPin, FiMail } from 'react-icons/fi'

const Main = () => {
  return (
    <div>
      <div>
        <div class='videowrapper'>
          <iframe
            src='https://player.vimeo.com/video/776875720?background=1muted=0autoplay=1controls=0loop=1'
            width='500'
            alt='BOMBARDIER GLOBAL5000 PRIVATE JET'
            height='281'
            frameborder='0'
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            title='EGE JETS'
          ></iframe>

          <div className='overlay'></div>

          <div className='content'>
            <h1>EGE JETS</h1>
          </div>
        </div>
        <div className='second'>
          <h4>CONTACT</h4>
          <div className='contact-info'>
            <div className='phone-numbers'>
              <br />
              {/* <p className='office'>
              <b>DUBAI OFFICE</b>
            </p> */}

              {/* <br />
            <a href='mailto:gokmen@egejets.com'>
              <FiMail className='icon' />
              &nbsp; gokmen@egejets.com
            </a> */}
            </div>
            <br />
            <a href='https://goo.gl/maps/UdrxKcogvpqvFfaE8' className='address'>
              <b>EGE JETS</b> <br />
              <FiMapPin />
              &nbsp; Baglar Mahallesi, Mimar Sinan Caddesi, Vegunesli Residence,
              Blok C, D53 PoBox 34212, Güneşli, Istanbul, TURKEY
            </a>
            <br />
            <br />
            <a href='tel:+90 533 041 7292'>
              <FiPhoneCall className='icon' />
              &nbsp; +90 533 041 7292
            </a>
            &nbsp; &nbsp; | &nbsp;&nbsp;
            <a href='tel:+971 50 209 17 74'>
              <FiPhoneCall className='icon' />
              &nbsp; +971 50 209 17 74
            </a>
            <br />
            <a href='mailto:ej@egejets.com'>
              <FiMail className='icon' />
              &nbsp; ej@egejets.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
